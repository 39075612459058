import React from "react";
import "./App.css";

function App() {
  return (
    <div className="container">
      <div className="box">
        <div className="xl-text">Desy Adelina R</div>
        <div>Software Engineering students</div>
      </div>

      <div className="box">
        <div className="md-text">I'm a developer experienced in building websites and mobile app development.</div>
      </div>
    </div>
  );
}

export default App;
